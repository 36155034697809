@import 'src/oppos-variables.less';

body {
  background: @layout-body-background;
  font-family: 'Roboto', sans-serif;
}

body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-track {
  background: @primary-color-contrast;
}

body::-webkit-scrollbar-thumb {
  background-color: @primary-color;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

nz-layout {
  background: @layout-body-background;
}

.ant-layout-sider-trigger {
  color: @white;
  background: @primary-color !important;
  [nz-icon] {
    color: @white;
  }

  &:hover {
    color: @black;
    background: @primary-color-contrast !important;
    [nz-icon] {
      color: @black;
    }
  }
}

.app-layout {
  width: 100%;
  height: 100%;
  min-height: 90vh;
}

.ant-form-item-required,
.ant-form-item {
  margin-bottom: 16px;
  font-size: @form-font-size;
}

.ant-form-vertical .ant-form-item-label > label {
  font-size: @form-font-size;
  color: @neutral-12;
}

.ant-form-item-label {
  font-size: @form-font-size;
  color: @neutral-12;
}

.ant-form-vertical .ant-form-item-label {
  &.ant-form-item-explain-error > label {
    color: @error-color;
    font-size: @form-font-size;
  }
}

.page-title {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-size: @form-font-size;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

[nz-icon] {
  color: @primary-color;
  &.icon-opacity {
    opacity: 0.2;
  }
}

[nz-icon] {
  &.form-error-icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 4px;
    color: @error-color;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.no-side-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.no-top-bottom-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.no-padding {
  padding: 0px !important;
}

.no-side-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.no-top-bottom-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.no-margin {
  margin: 0px !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: @black-2;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.ant-modal-header {
  border-radius: 8px;
  text-align: left;
  border-bottom: none;
  padding: 24px 32px 24px 32px;
}

.ant-modal-title {
  font-size: 24px;
}

.ant-modal-content {
  border-radius: 8px;
  text-align: left;
}

.ant-modal-body {
  padding: 0 32px;
  p {
    font-size: 16px;
    margin-bottom: 16px;
  }
}

.ant-modal-footer {
  text-align: center;
  border-top: none;
  padding: 16px 32px 24px 32px;
}

.ant-modal-close-x {
  margin-top: 10px;
  margin-right: 14px;
}

.ant-modal-close-x .ant-modal-close-icon svg {
  color: @neutral-10;
}

.ant-btn {
  font-size: 16px;
  height: 44px;
  width: 100%;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: @cta-oppos;
  border-color: @cta-oppos;
}

.ant-tabs-tab {
  color: @black-2;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.h2-small {
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.h2-large {
  font-family: 'Roboto';
  font-size: 38px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}

.h2-small {
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
}

.h2-medium {
  font-family: 'Roboto';
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
}

.h3-medium {
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.h5-medium {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.h5-regular {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.h5-regular-big {
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
}

.typography-avatar {
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.typography-1 {
  font-family: 'Roboto';
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
  color: @neutral-10;
}

.typography-2 {
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: @tag-incomplete-color;
}

.body-medium {
  font-family: 'Roboto';
  color: @black;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;

  span {
    font-family: 'Roboto';
    color: @black;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0em;
  }
}

.body-regular {
  color: @black;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.body-regular-lg {
  color: @black;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.breadcrumbs {
  color: @cta-oppos;

  &:hover {
    color: @cta-oppos-2;
  }
}

.ant-breadcrumb > nz-breadcrumb-item:last-child {
  color: @cta-oppos;

  &:hover {
    color: @cta-oppos-2;
  }
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: @cta-oppos;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: @cta-oppos;
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: @cta-oppos;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: @cta-oppos;
}

.ant-steps-icon > .anticon > svg {
  color: @cta-oppos;
}

.ant-progress-circle .ant-progress-circle{
  background-color: @cta-oppos;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
  visibility: visible !important;
}

.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  color: @neutral-2;
  font-size: 12px;
}

.ant-select-multiple .ant-select-selection-item {
  border: 1px solid @cta-oppos-selector;
  background: @cta-oppos-selector;
}
.group-selector .ant-select-selection-item-remove > .anticon {
  display: none;
}

.group-selector .ant-select-selection-item {
  border: none;
  background: none;
  padding: 0px;
}

.ant-table-cell {
  &.text-center {
    text-align: center;
  }
}
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: @white;
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: @white;
  border-right: 1px solid @primary-color-contrast-2;
  border-bottom: 1px solid @primary-color-contrast-2;
  border-left: 1px solid @primary-color-contrast-2;
}

.ant-modal-close-x {
  .anticon {
    font-size: 1.2rem;
    cursor: pointer;
    color: @neutral;
  }
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: @cta-oppos;
}

.ant-progress-text {
  color: @black-2;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.ant-tabs-nav-wrap {
  background: @white;
  border-bottom: 1px solid @neutral-11 !important;
}

.custom-form-title {
  color: @neutral-12;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  &.light {
    font-weight: 500;
  }
  &.bold {
    font-weight: 700;
  }
}

.tox-statusbar__branding {
  display: none;
}

/*NZ CARD*/

nz-card {
  &.suggested-card {
    border-radius: 4px;
    margin-bottom: 20px;
    box-shadow: 0px 9px 28px 8px @black-3;
    .ant-card-head {
      font-family: 'Roboto';
      font-size: 16px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      border-bottom: none;
    }

    .ant-card-body {
      padding-top: 0px;
    }
  }
}

/*NZ HEADERS*/

.header-policy-title > {
  .ant-page-header-heading-title {
    font-family: 'Roboto';
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.ant-page-header-back-button {
  font-size: 32px;
  [nz-icon] {
    font-size: 32px;
    color: @neutral-2;
  }
}

/*NZ Search group*/

nz-input-group {
  border: 1px solid @neutral-14;
  &.search-group {
    .ant-input-group-addon {
      background: @white;
    }
  }
}

.input-extra-large {
  min-height: 48px !important;
}

.button-modal-large {
  height: 44px !important;
}

.input-medium {
  min-height: 44px !important;
}

/*NZ Radio-group*/

.ant-radio-inner::after {
  background-color: @cta-oppos-2 !important;
}

/*NZ Table */

.ant-table-thead > tr > th {
  &.body-medium {
    color: @black;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
}

/*NZ Dropdown*/

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background-color: @cta-oppos-3 !important;
  &.body-regular {
    font-weight: 500;
  }
}

/** MENU ITEMS*/
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  flex: none !important;
  display: flex;
  align-items: center;
  text-decoration: none;
  a {
    width: 100%;
  }
}

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  margin-left: 10px !important;
}

/*NZ Badget*/
.ant-badge-status-processing::after {
  animation: none !important;
}

.ant-message-custom-content {
  text-align: left;
  font-size: 16px;
}

/*Buttons*/
.grc-primary-button {
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 0 16px;
  border-radius: 6px;
  width: inherit;
  color: @white;
  background-color: @btn-default;
  color: @white;
  &:hover {
    background-color: @btn-hover;
    color: @white;
  }
  &:active {
    background-color: @btn-active;
    color: @white;
  }
  &:focus {
    background-color: @btn-focus;
    color: @white;
  }
  &.disabled {
    background-color: @btn-disable;
    color: @neutral-10;
  }
  &.large {
    height: 48px !important;
  }
  &.medium {
    height: 44px !important;
  }
  &.small {
    height: 40px !important;
  }
  &.auto-width {
    width: auto;
  }
  &.large-medium {
    width: 220px;
  }
  &.large-width {
    width: 500px;
  }
}

.grc-secondary-button {
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 0 16px;
  border-radius: 6px;
  width: inherit;
  border-color: @btn-secondary-outline;
  color: @btn-default;
  &:hover {
    border-color: @btn-secondary-outline;
    background-color: @btn-secondary-background;
  }
  &:active {
    border-color: @btn-default;
    background-color: @btn-secondary-background;
  }
  &:focus {
    border-color: @btn-default;
    background-color: @btn-secondary-background;
  }
  &.disabled {
    border-color: @btn-disable;
    background-color: @btn-disable;
    color: @btn-secondary-outline;
  }
  &.large {
    height: 48px !important;
  }
  &.medium {
    height: 44px !important;
  }
  &.small {
    height: 40px !important;
  }
  &.auto-width {
    width: auto;
  }
  &.large-width {
    width: 500px;
  }
}

.grc-delete-button {
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 0 16px;
  border-radius: 6px;
  width: inherit;
  border-color: @btn-delete;
  color: @btn-delete;
  background-color: @white;
  &:hover {
    border-color: @btn-delete;
    color: @btn-delete;
    background-color: @btn-delete-background;
  }
  &:active {
    border-color: @btn-delete;
    color: @btn-delete;
    background-color: @btn-delete-active-background;
  }
  &:focus {
    border-color: @btn-delete;
    color: @btn-delete;
    background-color: @white;
  }
  &.large {
    height: 48px !important;
  }
  &.medium {
    height: 44px !important;
  }
  &.small {
    height: 40px !important;
  }
  &.auto-width {
    width: auto;
  }
}
