/* You can add global styles to this file, and also import other style files */

@white: #fff;
@black: #000;
@black-2: rgba(0, 0, 0, 0.85);
@black-3: #0000000d;
@neutral: #434343;
@neutral-2: #00000073;
@neutral-12: #141414;
@neutral-11: #ededed;
@neutral-10: #8c8c8c;
@neutral-13: #f9f9f9;
@neutral-14: #d9d9d9;
@background: #f9f9f9;
@btn-disable: #fafafa;
@primary-10: #002766;
@table-border: #e2e2e2;

@layout-body-background: #fff;

@primary-color: #450f8a;
@primary-color-contrast: #e4ddeb;
@primary-color-contrast-2: #d4d0f8;

@secondary-color: #6d20ba;
@secondary-color-contrast: #6d20ba;

@third-color: #877aff;
@third-color-contrast: #877aff;

@border-radius-base: 0px;

@error-color: #d01d26;
@success-color: #389e0d;

@tag-in-progress-color: #faad14;
@tag-error-color: #f5222d;
@tag-approved-color: #237804;
@tag-in-review-color: #0050b3;
@tag-incomplete-color: #595959;

@secondary-scrollbar-thumb: #c1c1c1;

// Font

@font-family: 'Roboto', sans-serif;
@form-font-size: 16px;

// Icons

@icon-success: #22c55e;
@icon-error: #d31a29;
@icon-avatar: #000000d9;

//Exceptions

@primary-blue-6: #1890ff;
@cta-oppos: #722ed1;
@cta-oppos-2: #531dab;
@cta-oppos-3: #f0e9f8;
@cta-oppos-selector: #f3f2ff;
@cta-oppos-select-icon: #dadada;
@cta-oppos-link: #874bd8;

//Button
@btn-default: #722ED1;
@btn-hover: #531EAB;
@btn-focus: #8E58DA;
@btn-active: #8043D6;
@btn-disable: #d9d9d9;

@btn-secondary-outline: #8c8c8c;
@btn-secondary-background: #e5ddeb;
@btn-delete: #cf1312;
@btn-delete-background: #f0f0f0;
@btn-delete-active-background: #fcf1f2;

//Card
@card-default-background: #f5f5f5;
@card-active-background: #f9f0ff;
